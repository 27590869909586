

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

p {
	line-height: 150%;
	margin-block-start: 1em;
	margin-block-end: 1em;
}

li {
	margin-top: 15px;
	margin-bottom: 15px;
}

.container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	font-family: Arial, sans-serif;
	background-color: #aebdd1;
}

/* Header styles */
.header {
	background-color: #4e6888;
	padding: 1rem;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
	position: sticky;
	top: 0;
}

.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
}

.logo {
	font-size: 1.5rem;
	color: #333;
}

.nav-links {
	display: flex;
	gap: 2rem;
	display: flex;
	align-items: center;
}

.nav-links a.link {
	color: white;
}

.link {
	text-decoration: none;
	color: #333;
	font-weight: 500;
	transition: color 0.3s ease;
}

.link:hover {
	color: #007bff;
}

.loginButton {
	padding: 3px;
}

.logoutButton {
	padding: 3px;
}

/* Main content styles */
.main {
	flex: 1;
	width: 100%;
	max-width: 800px;
	margin: 2rem auto;
	padding: 0 1rem;
}

.article {
	background-color: #ffffff;
	border-radius: 8px;
	padding: 2rem;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.title {
	font-size: 2.5rem;
	margin-bottom: 1rem;
	color: #333;
}

.metadata {
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
	color: #666;
}

.author {
	font-weight: 500;
	color: #888;
}

.date {
	font-weight: 500;
	color: #888;
}

.tags {
	display: flex;
	gap: 0.5rem;
	margin-bottom: 2rem;
	flex-wrap: wrap;
}

.tag {
	background-color: #f0f0f0;
	padding: 0.25rem 0.75rem;
	border-radius: 16px;
	font-size: 0.875rem;
	color: #666;
}

.content {
	margin-top: 1.5rem;
	line-height: 1.6;
	color: #444;
}

.paragraph {
	margin-bottom: 1.5rem;
}

/* Footer styles */
.footer {
	background-color: #333;
	color: #fff;
	color: gray;
	padding: 3rem 1rem 1rem;
}


.footer-content {
	display: flex;
	justify-content: space-between;
	max-width: 1200px;
	margin: 0 auto;
	gap: 2rem;
}

.footer-section {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.footer-title {
	font-size: 1.25rem;
	margin-bottom: 1rem;
}

.footer-link {
	color: #fff;
	text-decoration: none;
	transition: opacity 0.3s ease;
}

.footer-link:hover {
	opacity: 0.8;
	text-decoration: underline;
}

.copyright {
	max-width: 1200px;
	margin: auto;
	margin-top: 2rem;
	padding-top: 1rem;
	border-top: 1px solid rgba(255,255,255,0.1);
	font-size: 0.8em;
}

.copyright a {
	color: gray;
}

/* Responsive styles */
@media (max-width: 768px) {
	.nav {
/*		flex-direction: column;*/
		gap: 1rem;
	}

	.nav-links {
		flex-direction: column;
		gap: 0.5rem;
		text-align: center;
		display: none;
	}

	.title {
		font-size: 2rem;
	}

	.footer-content {
		flex-direction: column;
		text-align: center;
	}

	.metadata {
		flex-direction: column;
		gap: 0.5rem;
	}
}





.subscribe-form-container {
  max-width: 400px;
  margin: auto;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.subscribe-form-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 1rem;
}

.subscribe-form label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

.subscribe-form input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.subscribe-form button {
  width: 100%;
  padding: 0.5rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-form button:disabled {
  background-color: #aaa;
}

.subscribe-form button:hover:not(:disabled) {
  background-color: #0056b3;
}

.message {
  text-align: center;
  color: #28a745;
  margin-top: 1rem;
}

.homepage-background {
	width: 100%;
	min-height: 94vh;
	background-image: url("../public/homepg_bg.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;

}

.homepg a {
	color: black;
	text-decoration: none;
	font-size: 1.2em;
	line-height: 150%;
}

.recent-posts p {
	margin-bottom: 1.8em;
	padding-left: 2rem;
	padding-right: 2rem;
}


/* email link login css */

.email-link-login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.login-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.error-message {
  color: red;
  margin-bottom: 15px;
  font-size: 0.9em;
}

.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.email-sent-message {
  text-align: center;
  color: #28a745;
}

.email-instructions {
  color: #666;
  font-size: 0.9em;
  margin-top: 10px;
}


/* Register form css */


.email-link-auth {
  max-width: 300px;
  margin: 0 20px;
}

.email-link-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.email-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.email-sent-message {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}


/* Register form */

.signup-container {
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 10vh;
}

.signup-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  padding: 30px;
}

.signup-card h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.error-message {
  color: red;
  background-color: #ffeeee;
  border: 1px solid red;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  text-align: center;
}

.signup-button {
	background-color: #586d89;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.signup-button:hover {
  background-color: #45a049;
}


/* Complete signup css */

.complete-signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  text-align: center;
  padding: 20px;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 400px;
  width: 100%;
}

.error-card h2 {
  color: #d9534f;
  margin-bottom: 15px;
}

.retry-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.retry-button:hover {
  background-color: #45a049;
}
